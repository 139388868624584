export type FacilityDTO = {
  id: number;
  externalId: string;
  status: string;
  facilityName: string;
  facilityCategory: string;
  facilityType: string;
  facilityClass: string;
  contact: string;
  tel: string;
  email: string;
  manager: string;
  authority: string;
  subDistrictId: number;
  subDistrictName: string;
  districtId: number;
  districtName: string;
  clusterManager?: string;
  sunOverride: string;
  physicalAddress: string;
  longitude: string;
  latitude: string;
  accreditationIds: number[];
  hasAreaCapacity: boolean;
  hasPlacementSchedules: boolean;
};

export enum FacilityType {
  ACCOMMODATION = 'Accommodation',
  ACADEMIC = 'Academic',
  TRANSPORT = 'Transport'
}
