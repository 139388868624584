import { useCallback } from 'react';
import { useEnv } from 'src/common';
import { getDtoDateStringFromDate, useAxiosInstance } from 'src/lib';
import {
  getDtoFromWeekdays,
  getWeekdaysFromDto
} from 'src/services/dto-mappers';
import {
  PlacementScheduleStatus,
  RoomBooking,
  RoomBookingRecurrence,
  RoomBookingStatus,
  RoomBookingType
} from 'src/types/models/RoomBooking.type';
import {
  BookingGroupDTO,
  FacilityType,
  PlacementScheduleStatusDTO,
  RoomBookingDTO,
  RoomBookingRecurrenceDTO,
  RoomBookingStatusDTO,
  RoomBookingTypeDTO
} from 'src/types/dto';
import {
  BookingGroup,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { getDateFromDtoDateString } from 'src/lib/date-utils';

export interface IRoomBookingService {
  getRoomBookingsNew: GetAPI<RoomBooking, RoomBookingParams>;
  getRoomBookingsGrouped: GetAPI<BookingGroup, BookingGroupParams>;
  getRoomBooking: (
    roomBookingId: number,
    abortController?: AbortController
  ) => Promise<RoomBooking>;
  downloadRoomBookings: (params: DownloadRoomBookingParams) => void;
  deleteRoomBooking: (
    params: DeleteRoomBookingParams,
    abortController?: AbortController
  ) => Promise<void>;
  createBooking: (
    booking: RoomBooking,
    abortController?: AbortController
  ) => Promise<void>;
  updateBooking: (
    booking: RoomBooking,
    updateSeries: boolean,
    abortController?: AbortController
  ) => Promise<void>;
  approveBooking: (
    reviewRoomBookingParams: ReviewRoomBookingParams,
    abortController?: AbortController
  ) => Promise<void>;
  rejectBooking: (
    reviewRoomBookingParams: ReviewRoomBookingParams,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useRoomBookingService = (): IRoomBookingService => {
  const { apiUrl } = useEnv();

  const roomBookingApi = useAxiosInstance('/v2/bookings');

  const getRoomBookingsNew = useCallback(
    (
      request: PaginatedRequest<RoomBookingParams>
    ): Promise<PaginatedResponse<RoomBooking>> => {
      const { abortController, filters, ...requestParams } = request;

      return roomBookingApi
        .post('all', getRoomBookingBodyDto(filters), {
          params: {
            ...requestParams,
            ...getRoomBookingParamsDto(filters)
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<RoomBookingDTO>;

          return {
            items: content.map((contentItem) =>
              getRoomBookingFromDto(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          };
        });
    },
    [roomBookingApi]
  );

  const getRoomBookingsGrouped = useCallback(
    (
      request: PaginatedRequest<BookingGroupParams>
    ): Promise<PaginatedResponse<BookingGroup>> => {
      const { abortController, filters, ...requestParams } = request;

      return roomBookingApi
        .post('grouped', getRoomBookingGroupedBodyDto(filters), {
          params: {
            ...requestParams,
            ...getRoomBookingGroupParamsDto(filters)
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<BookingGroupDTO>;

          return {
            items: content.map((contentItem) =>
              getBookingsGroupModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          };
        });
    },
    [roomBookingApi]
  );

  const getRoomBooking = useCallback(
    (
      roomBookingId: number,
      abortController?: AbortController
    ): Promise<RoomBooking> => {
      return roomBookingApi
        .get(`${roomBookingId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const roomBookingDto = response.data as RoomBookingDTO;
          return getRoomBookingFromDto(roomBookingDto);
        });
    },
    [roomBookingApi]
  );

  const downloadRoomBookings = (
    downloadRoomBookingParams: DownloadRoomBookingParams
  ): void => {
    const downloadRoomBookingParamsDto = getDownloadRoomBookingParamsDto(
      downloadRoomBookingParams
    );
    const queryParams = new URLSearchParams(downloadRoomBookingParamsDto); // eslint-disable-next-line security/detect-non-literal-fs-filename

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(
      `${apiUrl}/v2/bookings/download?${queryParams.toString()}`,
      '_self'
    );
  };

  const deleteRoomBooking = useCallback(
    (
      params: DeleteRoomBookingParams,
      abortController?: AbortController
    ): Promise<void> => {
      const { id } = params;
      return roomBookingApi.delete(`${id}`, {
        params: getDeleteBookingParamsDto(params),
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [roomBookingApi]
  );

  const createBooking = useCallback(
    (
      booking: RoomBooking,
      abortController?: AbortController
    ): Promise<void> => {
      return roomBookingApi.post('', getDtoFromRoomBooking(booking), {
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [roomBookingApi]
  );

  const updateBooking = useCallback(
    (
      booking: RoomBooking,
      updateSeries: boolean,
      abortController?: AbortController
    ): Promise<void> => {
      return roomBookingApi.put(
        `${booking.id}`,
        getUpdateDtoFromRoomBooking(booking, updateSeries),
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [roomBookingApi]
  );

  const approveBooking = useCallback(
    (
      reviewRoomBookingParams: ReviewRoomBookingParams,
      abortController?: AbortController
    ): Promise<void> => {
      return roomBookingApi.post('/approve', null, {
        params: getReviewBookingParamsDto(reviewRoomBookingParams),
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [roomBookingApi]
  );

  const rejectBooking = useCallback(
    (
      reviewRoomBookingParams: ReviewRoomBookingParams,
      abortController?: AbortController
    ): Promise<void> => {
      return roomBookingApi.post('/reject', null, {
        params: getReviewBookingParamsDto(reviewRoomBookingParams),
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [roomBookingApi]
  );

  return {
    getRoomBooking,
    getRoomBookingsGrouped,
    getRoomBookingsNew,
    downloadRoomBookings,
    deleteRoomBooking,
    createBooking,
    updateBooking,
    approveBooking,
    rejectBooking
  };
};

function getRoomBookingFromDto(roomBookingDto: RoomBookingDTO): RoomBooking {
  return {
    ...roomBookingDto,
    status: getRoomBookingStatusFromDTO(roomBookingDto.status),
    approvalDateTime: roomBookingDto.approvalDateTime
      ? getDateFromDtoDateString(roomBookingDto.approvalDateTime)
      : undefined,
    startDateTime: getDateFromDtoDateString(roomBookingDto.startDateTime),
    endDateTime: getDateFromDtoDateString(roomBookingDto.endDateTime),
    bookingType: getRoomBookingType(roomBookingDto.bookingType),
    recurrence: getRoomBookingRecurrenceFromDto(roomBookingDto.recurrence),
    placementScheduleStatus: getPlacementScheduleStatusFromDTO(
      roomBookingDto.placementScheduleStatus
    )
  };
}

function getRoomBookingRecurrenceFromDto(
  roomBookingRecurrenceDto?: RoomBookingRecurrenceDTO
): RoomBookingRecurrence | undefined {
  if (roomBookingRecurrenceDto) {
    const { arrivalDate, departureDate, dayOfWeekSum } =
      roomBookingRecurrenceDto;
    return {
      ...roomBookingRecurrenceDto,
      enabled: true,
      arrivalDate: getDateFromDtoDateString(arrivalDate),
      departureDate: getDateFromDtoDateString(departureDate),
      daysOfWeek: getWeekdaysFromDto(dayOfWeekSum)
    };
  }
}

function getDtoFromRoomBooking(roomBooking: RoomBooking) {
  return {
    id: roomBooking.id > 0 ? roomBooking.id : undefined,
    startDateTime: getDtoDateStringFromDate(roomBooking.startDateTime),
    endDateTime: getDtoDateStringFromDate(roomBooking.endDateTime),
    trainingSiteId: roomBooking.trainingSiteId,
    trainingSiteName: roomBooking.trainingSiteName,
    facilityId: roomBooking.facilityId,
    facilityName: roomBooking.facilityName,
    capacity: roomBooking.capacity,
    title: roomBooking.title,
    notes: roomBooking.notes,
    bookedByUserId: roomBooking.bookedByUserId,
    lastUpdatedByUserId: roomBooking.lastUpdatedByUserId,
    approvedByUserId: roomBooking.approvedByUserId,
    status: roomBooking.status.toString(),
    comment: roomBooking.comment,
    approvalDateTime: roomBooking.approvalDateTime
      ? getDtoDateStringFromDate(roomBooking.approvalDateTime)
      : undefined,
    equipmentIds: roomBooking.equipmentIds,
    studentIds: roomBooking.studentIds,
    staffIds: roomBooking.staffIds,
    canApprove: roomBooking.canApprove,
    placementScheduleId: roomBooking.placementScheduleId,
    placementScheduleStatus: roomBooking.placementScheduleStatus,
    moduleScheduleId: roomBooking.moduleScheduleId,
    studentGroupId: roomBooking.studentGroupId,
    themeScheduleId: roomBooking.themeScheduleId,
    activityId: roomBooking.activityId,
    driverStaffId: roomBooking.driverStaffId,
    trainingSitesIds: roomBooking.trainingSitesIds,
    bookingType: getRoomBookingTypeDTO(roomBooking.bookingType),
    recurrence: roomBooking.recurrence
      ? {
          id: roomBooking.recurrence.id,
          arrivalDate: getDtoDateStringFromDate(
            roomBooking.recurrence.arrivalDate
          ),
          departureDate: getDtoDateStringFromDate(
            roomBooking.recurrence.departureDate
          ),
          dayOfWeekSum: getDtoFromWeekdays(roomBooking.recurrence.daysOfWeek)
        }
      : undefined,
    trainingSiteBookable: roomBooking.trainingSiteBookable,
    deletionReason: roomBooking.deletionReason,
    bookingKmTravelled: roomBooking.bookingKmTravelled
  };
}

function getUpdateDtoFromRoomBooking(
  roomBooking: RoomBooking,
  updateSeries: boolean
) {
  return {
    id: roomBooking.id > 0 ? roomBooking.id : undefined,
    editSeries: updateSeries,
    startDateTime: getDtoDateStringFromDate(roomBooking.startDateTime),
    endDateTime: getDtoDateStringFromDate(roomBooking.endDateTime),
    trainingSiteId: roomBooking.trainingSiteId,
    trainingSiteName: roomBooking.trainingSiteName,
    facilityId: roomBooking.facilityId,
    facilityName: roomBooking.facilityName,
    capacity: roomBooking.capacity,
    title: roomBooking.title,
    notes: roomBooking.notes,
    bookedByUserId: roomBooking.bookedByUserId,
    lastUpdatedByUserId: roomBooking.lastUpdatedByUserId,
    approvedByUserId: roomBooking.approvedByUserId,
    status: roomBooking.status.toString(),
    comment: roomBooking.comment,
    approvalDateTime: roomBooking.approvalDateTime
      ? getDtoDateStringFromDate(roomBooking.approvalDateTime)
      : undefined,
    equipmentIds: roomBooking.equipmentIds,
    studentIds: roomBooking.studentIds,
    staffIds: roomBooking.staffIds,
    canApprove: roomBooking.canApprove,
    placementScheduleId: roomBooking.placementScheduleId,
    placementScheduleStatus: roomBooking.placementScheduleStatus,
    moduleScheduleId: roomBooking.moduleScheduleId,
    studentGroupId: roomBooking.studentGroupId,
    themeScheduleId: roomBooking.themeScheduleId,
    activityId: roomBooking.activityId,
    driverStaffId: roomBooking.driverStaffId,
    trainingSitesIds: roomBooking.trainingSitesIds,
    bookingType: getRoomBookingTypeDTO(roomBooking.bookingType),
    recurrence: roomBooking.recurrence
      ? {
          id: roomBooking.recurrence.id,
          arrivalDate: getDtoDateStringFromDate(
            roomBooking.recurrence.arrivalDate
          ),
          departureDate: getDtoDateStringFromDate(
            roomBooking.recurrence.departureDate
          ),
          dayOfWeekSum: getDtoFromWeekdays(roomBooking.recurrence.daysOfWeek)
        }
      : undefined,
    trainingSiteBookable: roomBooking.trainingSiteBookable,
    deletionReason: roomBooking.deletionReason,
    bookingKmTravelled: roomBooking.bookingKmTravelled
  };
}

function getRoomBookingType(roomBookingType: string): RoomBookingType {
  switch (roomBookingType) {
    case RoomBookingTypeDTO.ACADEMIC:
      return RoomBookingType.ACADEMIC;
    case RoomBookingTypeDTO.ADHOC:
      return RoomBookingType.ADHOC;
    case RoomBookingTypeDTO.ACCOMMODATION:
      return RoomBookingType.ACCOMMODATION;
    case RoomBookingTypeDTO.TRANSPORT:
      return RoomBookingType.TRANSPORT;

    default:
      throw new Error(`unknown room booking type: ${roomBookingType}`);
  }
}

function getRoomBookingTypeDTO(roomBookingType: RoomBookingType): string {
  switch (roomBookingType) {
    case RoomBookingType.ACADEMIC:
      return RoomBookingTypeDTO.ACADEMIC;
    case RoomBookingType.ADHOC:
      return RoomBookingTypeDTO.ADHOC;
    case RoomBookingType.ACCOMMODATION:
      return RoomBookingTypeDTO.ACCOMMODATION;
    case RoomBookingType.TRANSPORT:
      return RoomBookingTypeDTO.TRANSPORT;
    default:
      throw new Error('unknown room booking type:');
  }
}

function getRoomBookingStatusFromDTO(
  roomBookingStatus: string
): RoomBookingStatus {
  switch (roomBookingStatus) {
    case RoomBookingStatusDTO.AWAITING_APPROVAL:
      return RoomBookingStatus.AWAITING_APPROVAL;
    case RoomBookingStatusDTO.APPROVED:
      return RoomBookingStatus.APPROVED;
    case RoomBookingStatusDTO.REJECTED:
      return RoomBookingStatus.REJECTED;
    case RoomBookingStatusDTO.CANCELLED:
      return RoomBookingStatus.CANCELLED;
    default:
      throw new Error(`unknown room booking status: ${roomBookingStatus}`);
  }
}

function getRoomBookingStatusDto(roomBookingStatus: RoomBookingStatus): string {
  switch (roomBookingStatus) {
    case RoomBookingStatus.AWAITING_APPROVAL:
      return RoomBookingStatusDTO.AWAITING_APPROVAL;
    case RoomBookingStatus.APPROVED:
      return RoomBookingStatusDTO.APPROVED;
    case RoomBookingStatus.REJECTED:
      return RoomBookingStatusDTO.REJECTED;
    case RoomBookingStatus.CANCELLED:
      return RoomBookingStatusDTO.CANCELLED;
  }
}

function getRoomBookingTypeDto(roomBookingType: RoomBookingType): string {
  switch (roomBookingType) {
    case RoomBookingType.ACADEMIC:
      return RoomBookingTypeDTO.ACADEMIC;
    case RoomBookingType.ADHOC:
      return RoomBookingTypeDTO.ADHOC;
    case RoomBookingType.ACCOMMODATION:
      return RoomBookingTypeDTO.ACCOMMODATION;
    case RoomBookingType.TRANSPORT:
      return RoomBookingTypeDTO.TRANSPORT;

    default:
      throw new Error('unknown room booking type');
  }
}

function getPlacementScheduleStatusFromDTO(
  placementScheduleStatusDto?: string
): PlacementScheduleStatus | undefined {
  switch (placementScheduleStatusDto) {
    case undefined:
      return undefined;
    case PlacementScheduleStatusDTO.ACTIVE:
      return PlacementScheduleStatus.ACTIVE;
    case PlacementScheduleStatusDTO.CANCELLED:
      return PlacementScheduleStatus.CANCELLED;
    default:
      throw new Error(
        `unknown placement schedule status: ${placementScheduleStatusDto}`
      );
  }
}

export type DeleteRoomBookingParams = {
  id: number;
  deleteReason: string;
  studentId?: number;
  isSeries?: boolean;
};

export type DownloadRoomBookingParams = {
  id?: number[];
  trainingSiteId?: number;
  facilityId?: number;
  facilityType?: FacilityType;
  status?: RoomBookingStatus;
  approverId?: number;
  startDateTime?: Date;
  endDateTime?: Date;
  bookingType?: RoomBookingType;
};

export type DeleteBookingParamsDto = {
  comment: string;
  studentId?: string;
  series?: string;
};

export type DownloadRoomBookingParamsDto = {
  id?: string;
  trainingSiteId?: string;
  facilityId?: string;
  facilityType?: string;
  status?: string;
  approverId?: string;
  startDateTime?: string;
  endDateTime?: string;
  bookingType?: string;
};

function getDownloadRoomBookingParamsDto(
  downloadRoomBookingParams: DownloadRoomBookingParams
): DownloadRoomBookingParamsDto {
  const downloadRoomBookingParamsDto: DownloadRoomBookingParamsDto = {};

  const {
    id,
    trainingSiteId,
    facilityId,
    status,
    approverId,
    startDateTime,
    endDateTime,
    bookingType
  } = downloadRoomBookingParams;

  if (id && id.length > 0) {
    downloadRoomBookingParamsDto.id = id.map((id) => id.toString()).join(',');
  }

  if (trainingSiteId) {
    downloadRoomBookingParamsDto.trainingSiteId = trainingSiteId.toString();
  }

  if (facilityId) {
    downloadRoomBookingParamsDto.facilityId = facilityId.toString();
  }

  if (status) {
    downloadRoomBookingParamsDto.status = getRoomBookingStatusDto(status);
  }

  if (approverId) {
    downloadRoomBookingParamsDto.approverId = approverId.toString();
  }

  if (bookingType) {
    downloadRoomBookingParamsDto.bookingType =
      getRoomBookingTypeDto(bookingType);
  }

  if (startDateTime) {
    downloadRoomBookingParamsDto.startDateTime =
      getDtoDateStringFromDate(startDateTime);
  }

  if (endDateTime) {
    downloadRoomBookingParamsDto.endDateTime =
      getDtoDateStringFromDate(endDateTime);
  }

  return downloadRoomBookingParamsDto;
}

function getDeleteBookingParamsDto(
  deleteBookingParams: DeleteRoomBookingParams
): DeleteBookingParamsDto {
  const { studentId, isSeries, deleteReason } = deleteBookingParams;

  const deleteBookingParamsDto: DeleteBookingParamsDto = {
    comment: deleteReason
  };

  if (studentId) {
    deleteBookingParamsDto.studentId = String(studentId);
  }

  if (isSeries) {
    deleteBookingParamsDto.series = String(isSeries);
  }

  return deleteBookingParamsDto;
}

export type RoomBookingParams = {
  facilityId?: number;
  bookingType?: RoomBookingType;
  ids?: number[];
  trainingAreaId?: number;
  approverId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentId?: number;
  startDateTime?: Date;
  endDateTime?: Date;
  startDateTimeExclusive?: Date;
  endDateTimeExclusive?: Date;
  studentGroupId?: number;
  bookingStatus?: RoomBookingStatus;
  bookedByUser?: boolean;
  searchText?: string;
  isHasConflicts?: boolean;
  simpleType?: boolean;
  roomBookingType?: RoomBookingType;
};

type RoomBookingsParamsDto = {
  facilityId?: number;
  bookingType?: string;
  ids?: string[];
  trainingSiteId?: number;
  approverId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentIds?: number[];
  startDateTime?: string;
  endDateTime?: string;
  startDateTimeExclusive?: string;
  endDateTimeExclusive?: string;
  studentGroupId?: number;
  status?: string;
  bookedByUser?: boolean;
  search?: string;
  hasConflicts?: boolean;
  simpleType?: boolean;
  roomBookingType?: string;
};

function getRoomBookingBodyDto(
  roomBookingParams?: RoomBookingParams
): number[] | undefined {
  if (roomBookingParams) {
    const { ids } = roomBookingParams;

    if (ids && ids.length > 0) {
      return ids;
    }
  }
}

function getRoomBookingParamsDto(
  roomBookingParams?: RoomBookingParams
): RoomBookingsParamsDto {
  if (!roomBookingParams) {
    return {};
  }

  const roomBookingsDto: RoomBookingsParamsDto = {};

  const {
    searchText,
    startDateTime,
    endDateTime,
    bookingType,
    bookedByUser,
    approverId,
    bookingStatus,
    trainingAreaId,
    isHasConflicts,
    simpleType,
    activityId,
    activityTypeId,
    facilityId,
    roomBookingType,
    startDateTimeExclusive,
    endDateTimeExclusive,
    studentGroupId,
    studentId
  } = roomBookingParams;

  if (searchText) {
    roomBookingsDto.search = searchText;
  }

  if (activityId) {
    roomBookingsDto.activityId = activityId;
  }

  if (activityTypeId) {
    roomBookingsDto.activityTypeId = activityTypeId;
  }

  if (facilityId) {
    roomBookingsDto.facilityId = facilityId;
  }

  if (startDateTime) {
    roomBookingsDto.startDateTime = getDtoDateStringFromDate(startDateTime);
  }

  if (endDateTime) {
    roomBookingsDto.endDateTime = getDtoDateStringFromDate(endDateTime);
  }

  if (startDateTimeExclusive) {
    roomBookingsDto.startDateTimeExclusive = getDtoDateStringFromDate(
      startDateTimeExclusive
    );
  }

  if (endDateTimeExclusive) {
    roomBookingsDto.endDateTimeExclusive =
      getDtoDateStringFromDate(endDateTimeExclusive);
  }

  if (bookingType !== undefined) {
    roomBookingsDto.bookingType = getRoomBookingTypeDto(bookingType);
  }

  if (bookedByUser !== undefined) {
    roomBookingsDto.bookedByUser = bookedByUser;
  }

  if (approverId !== undefined) {
    roomBookingsDto.approverId = approverId;
  }

  if (bookingStatus !== undefined) {
    roomBookingsDto.status = getRoomBookingStatusDto(bookingStatus);
  }

  if (trainingAreaId !== undefined) {
    roomBookingsDto.trainingSiteId = trainingAreaId;
  }

  if (simpleType !== undefined) {
    roomBookingsDto.simpleType = simpleType;
  }

  if (isHasConflicts !== undefined) {
    roomBookingsDto.hasConflicts = isHasConflicts;
  }

  if (roomBookingType !== undefined) {
    roomBookingsDto.roomBookingType = getRoomBookingTypeDTO(roomBookingType);
  }

  if (studentId !== undefined) {
    roomBookingsDto.studentIds = [studentId];
  }

  if (studentGroupId !== undefined) {
    roomBookingsDto.studentGroupId = studentGroupId;
  }

  return roomBookingsDto;
}

export type ReviewRoomBookingParams = {
  bookingId: number;
  comment?: string;
  isApproveSeries?: boolean;
  studentId?: number;
};

type ReviewRoomBookingParamsDto = {
  bookingId: number;
  comment?: string;
  seriesApproval?: boolean;
  studentId?: number;
};

function getReviewBookingParamsDto(
  reviewBookingParams: ReviewRoomBookingParams
): ReviewRoomBookingParamsDto {
  const reviewRoomBookingParamsDto: ReviewRoomBookingParamsDto = {
    bookingId: reviewBookingParams.bookingId
  };

  const { comment, isApproveSeries, studentId } = reviewBookingParams;

  if (comment) {
    reviewRoomBookingParamsDto.comment = comment;
  }

  if (isApproveSeries) {
    reviewRoomBookingParamsDto.seriesApproval = isApproveSeries;
  }

  if (studentId) {
    reviewRoomBookingParamsDto.studentId = studentId;
  }

  return reviewRoomBookingParamsDto;
}

export type BookingGroupParams = {
  facilityId?: number;
  bookingType?: RoomBookingType;
  ids?: number[];
  trainingAreaId?: number;
  approverId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentId?: number;
  startDateTime?: Date;
  endDateTime?: Date;
  studentGroupId?: number;
  bookingStatus?: RoomBookingStatus;
  bookedByUser?: boolean;
  searchText?: string;
  isHasConflicts?: boolean;
  simpleType?: boolean;
  groupBy?: BookingsGroupBy;
};

type BookingGroupParamsDto = {
  facilityId?: number;
  bookingType?: string;
  trainingSiteId?: number;
  approverId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentIds?: number[];
  startDateTime?: string;
  endDateTime?: string;
  studentGroupId?: number;
  status?: string;
  bookedByUser?: boolean;
  groupedBy?: string;
  search?: string;
  bookingIds?: number[];
  hasConflicts?: boolean;
  simpleType?: boolean;
};

function getRoomBookingGroupedBodyDto(
  roomBookingGroupParams?: BookingGroupParams
): number[] | undefined {
  if (roomBookingGroupParams) {
    const { ids } = roomBookingGroupParams;

    if (ids && ids.length > 0) {
      return ids;
    }
  }
}

function getRoomBookingGroupParamsDto(
  roomBookingGroupParams?: BookingGroupParams
): BookingGroupParamsDto {
  if (!roomBookingGroupParams) {
    return {};
  }

  const roomBookingsGroupDto: BookingGroupParamsDto = {};

  const {
    searchText,
    startDateTime,
    endDateTime,
    bookingType,
    bookedByUser,
    approverId,
    bookingStatus,
    trainingAreaId,
    isHasConflicts,
    simpleType,
    activityId,
    activityTypeId,
    facilityId,
    groupBy,
    studentId,
    studentGroupId
  } = roomBookingGroupParams;

  if (searchText) {
    roomBookingsGroupDto.search = searchText;
  }

  if (activityId) {
    roomBookingsGroupDto.activityId = activityId;
  }

  if (activityTypeId) {
    roomBookingsGroupDto.activityTypeId = activityTypeId;
  }

  if (facilityId) {
    roomBookingsGroupDto.facilityId = facilityId;
  }

  if (startDateTime) {
    roomBookingsGroupDto.startDateTime =
      getDtoDateStringFromDate(startDateTime);
  }

  if (endDateTime) {
    roomBookingsGroupDto.endDateTime = getDtoDateStringFromDate(endDateTime);
  }

  if (bookingType !== undefined) {
    roomBookingsGroupDto.bookingType = getRoomBookingTypeDto(bookingType);
  }

  if (bookedByUser !== undefined) {
    roomBookingsGroupDto.bookedByUser = bookedByUser;
  }

  if (approverId !== undefined) {
    roomBookingsGroupDto.approverId = approverId;
  }

  if (bookingStatus !== undefined) {
    roomBookingsGroupDto.status = getRoomBookingStatusDto(bookingStatus);
  }

  if (trainingAreaId !== undefined) {
    roomBookingsGroupDto.trainingSiteId = trainingAreaId;
  }

  if (simpleType !== undefined) {
    roomBookingsGroupDto.simpleType = simpleType;
  }

  if (isHasConflicts !== undefined) {
    roomBookingsGroupDto.hasConflicts = isHasConflicts;
  }

  if (groupBy !== undefined) {
    roomBookingsGroupDto.groupedBy = getDtoFromBookingsGroupBy(groupBy);
  }

  if (studentId !== undefined) {
    roomBookingsGroupDto.studentIds = [studentId];
  }

  if (studentGroupId !== undefined) {
    roomBookingsGroupDto.studentGroupId = studentGroupId;
  }

  return roomBookingsGroupDto;
}

function getBookingsGroupModel(dto: BookingGroupDTO): BookingGroup {
  const { date, ...rest } = dto;

  return {
    ...rest,
    date: date ? getDateFromDtoDateString(date) : undefined
  };
}

export enum BookingsGroupBy {
  FACILITY,
  DAY
}

export const BookingsGroupByDto = {
  FACILITY: 'facilityName',
  DAY: 'date'
};

function getDtoFromBookingsGroupBy(bookingsGroupBy: BookingsGroupBy) {
  switch (bookingsGroupBy) {
    case BookingsGroupBy.DAY:
      return BookingsGroupByDto.DAY;

    case BookingsGroupBy.FACILITY:
      return BookingsGroupByDto.FACILITY;
  }
}
