import { FormikErrors } from 'formik';
import {
  EditSeries,
  FormType,
  PermissionId,
  PlacementScheduleStudentDetails,
  RoomBookingStatus,
  RoomBookingType,
  Tag,
  TrainingArea
} from 'src/types';
import { FormProps, FormValues } from './form-types';
import { getDateTimeWithHoursMinutes, INLINE_ERRORS } from 'src/lib';
import { isEqual } from 'lodash';

const {
  DeleteTransportBooking,
  DeleteAccommodationBooking,
  DeleteAdhocBooking,
  ApproveAllTransportBookings,
  ApproveAllAccommodationBookings,
  ApproveAllAdhocBookings
} = PermissionId;

export const getInitialValues = (
  props: FormProps,
  moduleScheduleStudents: PlacementScheduleStudentDetails[]
): FormValues => {
  const DEFAULT_FORM_VALUES: FormValues = {
    id: 0,
    title: '',
    bookingType: props.bookingType,
    placementScheduleId: props.placementSchedule
      ? props.placementSchedule.id
      : 0,
    moduleScheduleId: props.placementSchedule
      ? props.placementSchedule.moduleScheduleId
      : 0,
    status: RoomBookingStatus.AWAITING_APPROVAL,
    recurrence: {
      id: undefined,
      enabled: false,
      arrivalDate: new Date(new Date().toDateString()),
      departureDate: new Date(new Date().toDateString()),
      dayOfWeekBitFieldAsEnumSet: [],
      daysOfWeek: []
    },
    startDateTime: new Date(new Date().toDateString()),
    endDateTime: new Date(new Date().toDateString()),
    equipment: [],
    facility: undefined,
    facilityType: props.facilityType,
    capacity: 0,
    trainingArea: undefined,
    trainingAreas: [],
    notes: '',
    staff: undefined,
    bookingKmTravelled: 0,
    individualStudents: [],
    isUpdateSeriesModalOpen: false
  };

  switch (props.formType) {
    case FormType.VIEW:
    case FormType.UPDATE: {
      const {
        id,
        title,
        bookingType,
        placementScheduleId,
        moduleScheduleId,
        status,
        recurrence,
        startDateTime,
        endDateTime,
        equipment,
        facility,
        trainingArea,
        capacity,
        facilityType,
        staff,
        driverStaff,
        notes,
        bookingKmTravelled,
        bookedByUser,
        lastUpdatedByUser,
        approvedByUser
      } = props.initialValues;

      const individualStudents: PlacementScheduleStudentDetails[] = [];

      if (
        props.initialValues.studentIds &&
        props.initialValues.studentIds.length > 0
      ) {
        moduleScheduleStudents.forEach(
          (student: PlacementScheduleStudentDetails) => {
            if (
              props.initialValues.studentIds &&
              props.initialValues?.studentIds.includes(student.id)
            ) {
              individualStudents.push(student);
            }
          }
        );
      }

      return {
        id,
        title,
        bookingType,
        placementScheduleId,
        moduleScheduleId,
        status: status,
        recurrence: recurrence
          ? recurrence
          : {
              id: 0,
              enabled: false,
              arrivalDate: new Date(),
              departureDate: new Date(),
              dayOfWeekBitFieldAsEnumSet: [],
              daysOfWeek: []
            },
        startDateTime,
        endDateTime,
        capacity,
        equipment: equipment ? equipment : [],
        facility: facility,
        facilityType,
        trainingArea,
        staff,
        driverStaff,
        notes,
        bookingKmTravelled,
        individualStudents,
        bookedByUser,
        lastUpdatedByUser,
        approvedByUser,
        isUpdateSeriesModalOpen: false
      };
    }
    case FormType.CREATE: {
      DEFAULT_FORM_VALUES.startDateTime = getDateTimeWithHoursMinutes(
        DEFAULT_FORM_VALUES.startDateTime,
        8,
        0
      );
      DEFAULT_FORM_VALUES.endDateTime = getDateTimeWithHoursMinutes(
        DEFAULT_FORM_VALUES.endDateTime,
        17,
        0
      );
      const individualStudents: PlacementScheduleStudentDetails[] = [];
      switch (props.bookingType) {
        case RoomBookingType.ACCOMMODATION:
          moduleScheduleStudents.forEach(
            (student: PlacementScheduleStudentDetails) => {
              if (
                props.placementSchedule?.accommodationStudentsIds.includes(
                  student.id
                )
              ) {
                individualStudents.push(student);
              }
            }
          );
          break;
        case RoomBookingType.TRANSPORT:
          moduleScheduleStudents.forEach(
            (student: PlacementScheduleStudentDetails) => {
              if (
                props.placementSchedule?.transportStudentsIds.includes(
                  student.id
                )
              ) {
                individualStudents.push(student);
              }
            }
          );
          break;
      }

      const { placementSchedule } = props;
      return {
        ...DEFAULT_FORM_VALUES,
        /* pull the below initial values from the placement schedule */
        individualStudents: individualStudents,
        title: placementSchedule
          ? placementSchedule.themeName
          : DEFAULT_FORM_VALUES.title,
        startDateTime: placementSchedule
          ? placementSchedule.recurrence.enabled
            ? placementSchedule.recurrence.activeDate
            : placementSchedule.startDateTime
          : DEFAULT_FORM_VALUES.startDateTime,
        endDateTime: placementSchedule
          ? placementSchedule.recurrence.enabled
            ? placementSchedule.recurrence.expiryDate
            : placementSchedule.endDateTime
          : DEFAULT_FORM_VALUES.endDateTime,
        recurrence: {
          ...DEFAULT_FORM_VALUES.recurrence,
          enabled: placementSchedule?.recurrence.enabled ?? false,
          daysOfWeek: placementSchedule?.recurrence
            ? placementSchedule.recurrence.weekdays
            : []
        }
      };
    }
  }
};

export const getOnSubmit = (
  props: FormProps
): ((values: FormValues) => Promise<void>) => {
  const getBooking = getBookingFromFormValues(props);

  switch (props.formType) {
    case FormType.UPDATE:
      return (values) => {
        const { recurrence: reoccurenceOld } = props.initialValues;
        const { recurrence: reoccurenceNew } = values;

        return props.onSaveBooking(
          getBooking(values),
          (!reoccurenceOld?.enabled && reoccurenceNew.enabled) || // if it wasn't reoccuring but now it is
            values.editSeries === EditSeries.SERIES
        );
      };
    case FormType.CREATE:
      return (values) => props.onCreateBooking(getBooking(values));
    case FormType.VIEW:
      return () => {
        throw new Error('read only form should not call submit');
      };
    default:
      throw new Error('unsupported form type');
  }
};

export const getValidate =
  (formProps: FormProps) =>
  (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    const requiredFields = [
      'title',
      formProps.formType === FormType.CREATE ? 'trainingAreas' : 'trainingArea',
      'facility'
    ] as (keyof FormValues)[];
    requiredFields.forEach((field) => {
      if (!values[`${field}`]) {
        errors[`${field}`] = INLINE_ERRORS.REQUIRED;
      }
    });

    // check array props if they are empty
    if (values.trainingAreas && values.trainingAreas.length === 0) {
      errors['trainingAreas'] = INLINE_ERRORS.REQUIRED;
    }

    if (
      formProps.formType === FormType.CREATE &&
      values.trainingAreas &&
      values.trainingAreas.some(
        (trainingSite) => Math.max(trainingSite.remainingCapacity ?? 0, 0) < 1
      )
    ) {
      errors['trainingAreas'] =
        'Training area selected with zero remaining capacity';
    }

    return errors;
  };

const getBookingFromFormValues =
  (formProps: FormProps) => (values: FormValues) => {
    const {
      id,
      title,
      status,
      placementScheduleId,
      moduleScheduleId,
      bookingType,
      recurrence,
      startDateTime,
      endDateTime,
      equipment,
      facility,
      facilityType,
      capacity,
      trainingArea,
      trainingAreas,
      notes,
      staff,
      driverStaff,
      bookingKmTravelled,
      individualStudents
    } = values;

    const studentIds: number[] = [];
    const transportStudentsIds: number[] = [];
    const accomodationStudentsIds: number[] = [];
    const conflictOverriddenStudentsIds: number[] = [];
    const excludedGroupStudentsIds: number[] = [];
    const bookableStudentIds: number[] = [];
    const trainingSitesIds: number[] = [];
    const trainingSiteId = trainingArea
      ? trainingArea.id
      : trainingAreas
      ? trainingAreas[0].id
      : 0;
    const trainingSiteName = trainingArea
      ? trainingArea.areaLocation
      : trainingAreas
      ? trainingAreas[0].areaLocation
      : '';

    if (individualStudents.length > 0) {
      individualStudents.forEach((student: PlacementScheduleStudentDetails) => {
        studentIds.push(student.id);
        if (student.needTransport) {
          transportStudentsIds.push(student.id);
        }
        if (student.needAccommodation) {
          accomodationStudentsIds.push(student.id);
        }
        if (student.bookable) {
          bookableStudentIds.push(student.id);
        }
        if (student.excluded) {
          excludedGroupStudentsIds.push(student.id);
        }
        if (student.conflictOverridden) {
          conflictOverriddenStudentsIds.push(student.id);
        }
      });
    }

    const equipmentIds: number[] = [];
    if (equipment) {
      equipment.forEach((equipment: Tag) => {
        equipmentIds.push(equipment.id);
      });
    }

    if (trainingArea) {
      trainingSitesIds.push(trainingArea.id);
    }

    if (trainingAreas) {
      trainingAreas.forEach((trainingArea: TrainingArea) => {
        trainingSitesIds.push(trainingArea.id);
      });
    }
    const staffIds: number[] = [];
    if (staff) {
      staffIds.push(staff.id);
    }

    return {
      activityId: formProps.placementSchedule?.activityId,
      approvalDateTime: new Date(0),
      approvedByUserId: 0,
      bookedByUserId: 0,
      bookingKmTravelled,
      bookingType,
      canApprove: true,
      capacity,
      comment: '',
      deletionReason: '',
      driverStaffId: driverStaff ? driverStaff.id : 0,
      endDateTime,
      equipmentIds: equipment ? equipmentIds : undefined,
      facilityId: facility ? facility.id : 0,
      facilityName: facility ? facility.facilityName : '',
      facilityType: facilityType,
      id: id ? id : 0,
      lastUpdatedByUserId: 0,
      moduleScheduleId: moduleScheduleId,
      notes,
      placementScheduleId: placementScheduleId,
      placementScheduleStatus: undefined,
      recurrence:
        recurrence && recurrence.enabled
          ? {
              id: recurrence.id,
              enabled: recurrence.enabled,
              arrivalDate: recurrence.arrivalDate,
              departureDate: recurrence.departureDate,
              daysOfWeek: recurrence.daysOfWeek
            }
          : undefined,
      staffIds,
      startDateTime,
      status,
      studentGroupId: 0,
      studentIds,
      themeScheduleId: 0,
      title,
      trainingSiteBookable: true,
      trainingSiteId: trainingSiteId,
      trainingSiteName: trainingSiteName,
      trainingSitesIds,
      conflictsCount: 0
    };
  };

export const getDeletePermission = (
  bookingType: RoomBookingType
): PermissionId => {
  switch (bookingType) {
    case RoomBookingType.TRANSPORT:
      return DeleteTransportBooking;
    case RoomBookingType.ACCOMMODATION:
      return DeleteAccommodationBooking;
    case RoomBookingType.ADHOC:
      return DeleteAdhocBooking;
    default:
      throw new Error('unsupported booking type');
  }
};

export const getApprovePermission = (
  bookingType: RoomBookingType
): PermissionId => {
  switch (bookingType) {
    case RoomBookingType.TRANSPORT:
      return ApproveAllTransportBookings;
    case RoomBookingType.ACCOMMODATION:
      return ApproveAllAccommodationBookings;
    case RoomBookingType.ADHOC:
      return ApproveAllAdhocBookings;
    default:
      throw new Error('unsupported booking type');
  }
};

export function isUpdateFormSaveButtonVisible(
  initialValues: FormValues,
  currentValues: FormValues
): boolean {
  /* the editSeries value is updated after the form is initialised
   * if the booking is reoccurring, when the user makes a choice
   * via the modal. We only want to show the save button if any
   * other form value has changed.
   */
  const initialValuesCleaned: FormValues = {
    ...initialValues,
    editSeries: undefined
  };
  const currentValuesCleaned: FormValues = {
    ...currentValues,
    editSeries: undefined
  };

  return !isEqual(initialValuesCleaned, currentValuesCleaned);
}
