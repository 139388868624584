import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { RoutesContainer, ApplicationLayout } from './components';
import {
  NavigationHeaderContainer,
  VerticalNavigationBarContainer
} from './containers';
import { ApplicationEnvironment } from './common';

export const ApplicationModule: FC = () => {
  return (
    <ApplicationEnvironment>
      <>
        <CssBaseline />

        <ApplicationLayout
          navigation={<VerticalNavigationBarContainer />}
          header={<NavigationHeaderContainer />}
          mainContent={
            <>
              <RoutesContainer />
              <Outlet />
            </>
          }
        />
      </>
    </ApplicationEnvironment>
  );
};

export default ApplicationModule;
