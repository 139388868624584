import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import {
  AcademicBookingDTO,
  AcademicBookingStatus,
  DownloadRoster,
  DownloadRosterDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  PlacementSchedule,
  PlacementScheduleCreateResponse,
  PlacementScheduleCreateResponseDTO,
  PlacementScheduleDTO,
  PlacementScheduleGroup,
  PlacementScheduleGroupDTO,
  Recurrence,
  RecurrenceDTO,
  StudentDTO,
  Supervisor,
  Weekday
} from 'src/types';
import {
  DATE_TIME_FORMAT,
  getDtoDateStringFromDate,
  isValidDate,
  useAxiosInstance
} from 'src/lib';
import { useEnv } from 'src/common';
import { getDateFromDtoDateString } from '../lib';
import { PlacementScheduleDetail } from 'src/modules/bookings/bookings/academic/services/placement-schedule-details';

export interface IPlacementSchedulesService {
  getPlacementSchedules: GetAPI<PlacementSchedule>;
  getPlacementSchedulesNew: GetAPI<PlacementSchedule, PlacementScheduleParams>;
  getPlacementSchedulesGrouped: GetAPI<
    PlacementScheduleGroup,
    PlacementScheduleGroupParams
  >;
  getAllPlacementSchedules: GetAPI<PlacementSchedule>; // TODO: improve name
  getPlacementSchedule: (
    placementScheduleId: number
  ) => Promise<PlacementSchedule>;
  downloadPlacementSchedules: (
    downloadRoster: DownloadRoster,
    abortController?: AbortController
  ) => void;
  deletePlacementSchedule: (
    id: number,
    deleteReason: string,
    abortController?: AbortController
  ) => Promise<void>;
  cancelPlacementSchedule: (
    id: number,
    reason: string,
    abortController?: AbortController
  ) => Promise<void>;
  updatePlacementScheduleWithAutoAllocate: (
    placementSchedule: PlacementScheduleDetail,
    updateSeries: boolean,
    abortController?: AbortController
  ) => Promise<PlacementScheduleCreateResponse>;
  createPlacementSchedule: (
    placementSchedule: PlacementScheduleDetail,
    abortController?: AbortController
  ) => Promise<PlacementScheduleCreateResponse>;
  updatePlacementSchedule: (
    placementSchedule: PlacementScheduleDetail,
    updateSeries: boolean,
    abortController?: AbortController
  ) => Promise<PlacementScheduleCreateResponse>;
  getStudentsWithPlacement: (
    startDate: Date,
    endDate: Date,
    studentIds: number[],
    placementScheduleId?: number,
    recurrence?: Recurrence,
    isEditingSeries?: boolean,
    abortController?: AbortController
  ) => Promise<StudentDTO[]>;
  getStudentGroupsWithPlacement: (
    startDate: Date,
    endDate: Date,
    studentGroupIds: number[],
    placementScheduleId?: number,
    recurrence?: Recurrence,
    isEditingSeries?: boolean,
    abortController?: AbortController
  ) => Promise<StudentDTO[]>;
}

export const usePlacementSchedulesService = (): IPlacementSchedulesService => {
  const { apiUrl } = useEnv();

  const placementSchedulesApi = useAxiosInstance('/v2/placementSchedules');

  const getPlacementSchedules = useCallback(
    (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<PlacementSchedule>> => {
      return placementSchedulesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<PlacementScheduleDTO>;

          return {
            items: content.map((contentItem) =>
              getPlacementScheduleModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<PlacementSchedule>;
        });
    },
    [placementSchedulesApi]
  );

  const getPlacementSchedulesNew = useCallback(
    (
      request: PaginatedRequest<PlacementScheduleParams>
    ): Promise<PaginatedResponse<PlacementSchedule>> => {
      const { filters, ...restOfParams } = request;

      return placementSchedulesApi
        .get('', {
          params: {
            ...restOfParams,
            ...getPlacementScheduleParamsDTO(filters)
          },
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<PlacementScheduleDTO>;

          return {
            items: content.map((contentItem) =>
              getPlacementScheduleModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<PlacementSchedule>;
        });
    },
    [placementSchedulesApi]
  );

  const getPlacementSchedulesGrouped = useCallback(
    (
      request: PaginatedRequest<PlacementScheduleGroupParams>
    ): Promise<PaginatedResponse<PlacementScheduleGroup>> => {
      const { filters, ...restOfParams } = request;

      return placementSchedulesApi
        .get('grouped', {
          params: {
            ...restOfParams,
            ...getPlacementScheduleGroupParamsDTO(filters)
          },
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<PlacementScheduleGroupDTO>;

          return {
            items: content.map((contentItem) =>
              getPlacementScheduleGroupModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          };
        });
    },
    [placementSchedulesApi]
  );

  const getAllPlacementSchedules = useCallback(
    (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<PlacementSchedule>> => {
      return placementSchedulesApi
        .get('all', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<PlacementScheduleDTO>;

          return {
            items: content.map((contentItem) =>
              getPlacementScheduleModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<PlacementSchedule>;
        });
    },
    [placementSchedulesApi]
  );

  const getPlacementSchedule = useCallback(
    (
      placementScheduleId: number,
      abortController?: AbortController
    ): Promise<PlacementSchedule> => {
      return placementSchedulesApi
        .get(`${placementScheduleId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) =>
          getPlacementScheduleModel(response.data as PlacementScheduleDTO)
        );
    },
    [placementSchedulesApi]
  );

  const downloadPlacementSchedules = (downloadRoster: DownloadRoster): void => {
    const downloadRosterDto: DownloadRosterDTO =
      getDtoFromDownloadRoster(downloadRoster);
    const queryParams = new URLSearchParams(downloadRosterDto); // eslint-disable-next-line security/detect-non-literal-fs-filename

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(
      `${apiUrl}/v2/placementSchedules/download?${queryParams.toString()}`
    );
  };

  const deletePlacementSchedule = useCallback(
    (
      id: number,
      deleteReason: string,
      abortController?: AbortController
    ): Promise<void> =>
      placementSchedulesApi.delete(`${id}?deletionReason=${deleteReason}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [placementSchedulesApi]
  );

  const cancelPlacementSchedule = useCallback(
    (
      id: number,
      reason: string,
      abortController?: AbortController
    ): Promise<void> =>
      placementSchedulesApi.post(`cancel/${id}?cancellationReason=${reason}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [placementSchedulesApi]
  );

  const updatePlacementScheduleWithAutoAllocate = (
    placementSchedule: PlacementScheduleDetail,
    updateSeries: boolean,
    abortController?: AbortController
  ): Promise<PlacementScheduleCreateResponse> => {
    return placementSchedulesApi
      .post(
        'allocate',
        getUpdatePlacementScheduleModel(placementSchedule, updateSeries),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      )
      .then((response: AxiosResponse) => {
        const placementScheduleCreateResponseDTO: PlacementScheduleCreateResponseDTO =
          response.data;
        return getPlacementScheduleCreateResponseDTO(
          placementScheduleCreateResponseDTO
        );
      });
  };

  const createPlacementSchedule = (
    placementSchedule: PlacementScheduleDetail,
    abortController?: AbortController
  ): Promise<PlacementScheduleCreateResponse> => {
    return placementSchedulesApi
      .post('', getCreatePlacementScheduleModel(placementSchedule), {
        withCredentials: true,
        signal: abortController?.signal
      })
      .then((response: AxiosResponse) => {
        const placementScheduleCreateResponseDTO: PlacementScheduleCreateResponseDTO =
          response.data;
        return getPlacementScheduleCreateResponseDTO(
          placementScheduleCreateResponseDTO
        );
      });
  };

  const updatePlacementSchedule = (
    placementSchedule: PlacementScheduleDetail,
    updateSeries: boolean,
    abortController?: AbortController
  ): Promise<PlacementScheduleCreateResponse> =>
    placementSchedulesApi
      .put(
        `${placementSchedule.id}`,
        getUpdatePlacementScheduleModel(placementSchedule, updateSeries),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      )
      .then((response: AxiosResponse) => {
        const placementScheduleCreateResponseDTO: PlacementScheduleCreateResponseDTO =
          response.data;
        return getPlacementScheduleCreateResponseDTO(
          placementScheduleCreateResponseDTO
        );
      });

  const getStudentsWithPlacement = (
    startDate: Date,
    endDate: Date,
    studentIds: number[],
    placementScheduleId?: number,
    recurrence?: Recurrence,
    isEditingSeries?: boolean,
    abortController?: AbortController
  ): Promise<StudentDTO[]> => {
    return placementSchedulesApi
      .get('students-with-placements', {
        params: {
          startDateTime: moment(startDate).format(
            DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
          ),
          endDateTime: moment(endDate).format(
            DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
          ),
          studentsIds: studentIds,
          daysOfWeekBit:
            recurrence && recurrence.enabled
              ? getBitFieldFromWeekDays(recurrence.weekdays)
              : undefined,
          excludedPlacementId: placementScheduleId,
          isEditingSeries: isEditingSeries ?? false
        },
        withCredentials: true,
        signal: abortController?.signal
      })
      .then((response) => {
        const students = response.data as StudentDTO[];

        return students;
      });
  };

  const getStudentGroupsWithPlacement = (
    startDate: Date,
    endDate: Date,
    studentGroupIds: number[],
    placementScheduleId?: number,
    recurrence?: Recurrence,
    isEditingSeries?: boolean,
    abortController?: AbortController
  ): Promise<StudentDTO[]> =>
    placementSchedulesApi
      .get('groups/students-with-placements', {
        params: {
          startDateTime: moment(startDate).format(
            DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
          ),
          endDateTime: moment(endDate).format(
            DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
          ),
          studentGroupsIds: studentGroupIds,
          daysOfWeekBit:
            recurrence && recurrence.enabled
              ? getBitFieldFromWeekDays(recurrence.weekdays)
              : undefined,
          excludedPlacementId: placementScheduleId,
          isEditingSeries: isEditingSeries ?? false
        },
        withCredentials: true,
        signal: abortController?.signal
      })
      .then((response) => {
        const students = response.data as StudentDTO[];

        return students;
      });

  return {
    getPlacementSchedules,
    getPlacementSchedulesNew,
    getPlacementSchedulesGrouped,
    getAllPlacementSchedules,
    getPlacementSchedule,
    downloadPlacementSchedules,
    deletePlacementSchedule,
    cancelPlacementSchedule,
    createPlacementSchedule,
    updatePlacementScheduleWithAutoAllocate,
    updatePlacementSchedule,
    getStudentsWithPlacement,
    getStudentGroupsWithPlacement
  };
};

export enum PlacementScheduleGroupBy {
  FACILITY,
  DAY
}

export const PlacementScheduleGroupByDto = {
  FACILITY: 'facilityName',
  DAY: 'date'
};

function getDtoFromPlacementScheduleGroupBy(
  placementScheduleGroupBy: PlacementScheduleGroupBy
) {
  switch (placementScheduleGroupBy) {
    case PlacementScheduleGroupBy.DAY:
      return PlacementScheduleGroupByDto.DAY;

    case PlacementScheduleGroupBy.FACILITY:
      return PlacementScheduleGroupByDto.FACILITY;
  }
}

export type PlacementScheduleGroupParams = {
  moduleScheduleId?: number;
  themeScheduleId?: number;
  facilityId?: number;
  trainingAreaId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentId?: number;
  startDateTime?: Date;
  endDateTime?: Date;
  approverId?: number;
  studentGroupId?: number;
  bookingStatus?: AcademicBookingStatus;
  bookedByUser?: boolean;
  groupBy?: PlacementScheduleGroupBy;
  search?: string;
};

type PlacementScheduleGroupParamsDto = {
  moduleScheduleId?: number;
  themeScheduleId?: number;
  facilityId?: number;
  trainingAreaId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentId?: number;
  startDateTime?: string;
  endDateTime?: string;
  approverId?: number;
  studentGroupId?: number;
  bookingStatus?: string;
  bookedByUser?: boolean;
  groupedBy?: string;
  search?: string;
};

export type PlacementScheduleParams = {
  moduleScheduleId?: number;
  themeScheduleId?: number;
  facilityId?: number;
  trainingAreaId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentId?: number;
  startDateTime?: Date;
  endDateTime?: Date;
  approverId?: number;
  studentGroupId?: number;
  bookingStatus?: AcademicBookingStatus;
  bookedByUser?: boolean;
  search?: string;
};

type PlacementScheduleParamsDto = {
  moduleScheduleId?: number;
  themeScheduleId?: number;
  facilityId?: number;
  trainingAreaId?: number;
  activityId?: number;
  activityTypeId?: number;
  studentId?: number;
  startDateTime?: string;
  endDateTime?: string;
  approverId?: number;
  studentGroupId?: number;
  bookingStatus?: string;
  bookedByUser?: boolean;
  search?: string;
};

function getDtoFromDownloadRoster(
  downloadRoster: DownloadRoster
): DownloadRosterDTO {
  const {
    startDate,
    endDate,
    moduleScheduleId,
    themeScheduleId,
    activityTypeId,
    activityTopicId,
    facilityId,
    trainingAreaIds,
    groupIds,
    studentIds,
    supervisorIds
  } = downloadRoster;

  return {
    startDate: startDate ? getDtoDateStringFromDate(startDate) : '',
    endDate:
      endDate && isValidDate(endDate) ? getDtoDateStringFromDate(endDate) : '',
    themeScheduleId: themeScheduleId ? themeScheduleId.toString() : '',
    moduleScheduleId: moduleScheduleId ? moduleScheduleId.toString() : '',
    activityTypeId: activityTypeId ? activityTypeId.toString() : '',
    activityTopicId: activityTopicId ? activityTopicId.toString() : '',
    facilityId: facilityId ? facilityId.toString() : '',
    trainingAreaIds: trainingAreaIds
      ? trainingAreaIds.map((id: number) => id.toString()).join(',')
      : '',
    groupIds: groupIds
      ? groupIds.map((id: number) => id.toString()).join(',')
      : '',
    studentIds: studentIds
      ? studentIds.map((id: number) => id.toString()).join(',')
      : '',
    supervisorIds: supervisorIds
      ? supervisorIds.map((id: number) => id.toString()).join(',')
      : ''
  };
}
function getPlacementScheduleGroupModel(
  dto: PlacementScheduleGroupDTO
): PlacementScheduleGroup {
  const { date, ...rest } = dto;

  return {
    ...rest,
    date: date ? getDateFromDtoDateString(date) : undefined
  };
}

function getPlacementScheduleModel(
  dto: PlacementScheduleDTO
): PlacementSchedule {
  const {
    startDateTime,
    endDateTime,
    createdDateTime,
    lastUpdatedDateTime,
    recurrence,
    academicBookingStatus
  } = dto;
  return {
    ...dto,
    startDateTime: getDateFromDtoDateString(startDateTime),
    endDateTime: getDateFromDtoDateString(endDateTime),
    createdDateTime: getDateFromDtoDateString(createdDateTime),
    lastUpdatedDateTime: getDateFromDtoDateString(lastUpdatedDateTime),
    supervisors: [],
    recurrence: getRecurrenceModel(recurrence),
    academicBookingStatus: getAcademicBookingStatusFromDTO(
      academicBookingStatus
    ),
    students: []
  };
}

function getCreatePlacementScheduleModel(
  placementSchedule: PlacementScheduleDetail
) {
  return {
    activityTypeId: placementSchedule.activityTypeId,
    activityId: placementSchedule.activityId,
    startTime: `${placementSchedule.startDateTime.getHours()}:${placementSchedule.startDateTime.getMinutes()} `,
    endTime: `${placementSchedule.endDateTime.getHours()}:${placementSchedule.endDateTime.getMinutes()} `,
    startDate: placementSchedule.startDateTime,
    endDate: placementSchedule.endDateTime,
    startDateTime: placementSchedule.startDateTime,
    endDateTime: placementSchedule.endDateTime,
    recurrence: {
      activeDate: placementSchedule.startDateTime,
      expiryDate: placementSchedule.endDateTime,
      enabled: placementSchedule.recurrence.enabled,
      weekdaysBitField: getBitFieldFromWeekDays(
        placementSchedule.recurrence.weekdays
      )
    },
    trainingSiteIds: placementSchedule.trainingSiteIds,
    trainingSiteId:
      placementSchedule.trainingArea?.id ?? placementSchedule.trainingSiteId,
    themeScheduleId: placementSchedule.themeScheduleId,
    capacity: placementSchedule.capacity,
    supervisorIds: placementSchedule.supervisors.map(
      (supervisor: Supervisor) => supervisor.id
    ),
    studentGroupId: placementSchedule.studentGroupId,
    studentGroupIds:
      placementSchedule.studentGroupIds &&
      placementSchedule.studentGroupIds.length > 0
        ? placementSchedule.studentGroupIds
        : null,
    studentIds: placementSchedule.studentIds?.map(
      (studentId: number) => studentId
    ),
    conflictOverriddenStudentsIds:
      placementSchedule.conflictOverriddenStudentsIds,
    transportStudentsIds:
      placementSchedule.transportStudentsIds.length > 0
        ? placementSchedule.transportStudentsIds
        : null,
    accommodationStudentsIds:
      placementSchedule.accommodationStudentsIds.length > 0
        ? placementSchedule.accommodationStudentsIds
        : null,
    bookableStudentIds:
      placementSchedule.bookableStudentsIds.length > 0
        ? placementSchedule.bookableStudentsIds
        : null,
    excludedGroupStudentsIds:
      placementSchedule.excludedGroupStudentsIds.length > 0
        ? placementSchedule.excludedGroupStudentsIds
        : null,
    notes: placementSchedule.notes,
    equipmentIds: placementSchedule.equipmentIds
  };
}

function getUpdatePlacementScheduleModel(
  placementSchedule: PlacementScheduleDetail,
  updateSeries: boolean
) {
  return {
    id: placementSchedule.id,
    activityTypeId: placementSchedule.activityTypeId,
    activityId: placementSchedule.activityId,
    startDateTime: placementSchedule.startDateTime,
    endDateTime: placementSchedule.endDateTime,
    roomBookingId: placementSchedule.roomBookingId,
    recurrence: {
      series: updateSeries,
      future: updateSeries,
      id: placementSchedule.recurrence.id,
      activeDate: placementSchedule.startDateTime,
      expiryDate: placementSchedule.endDateTime,
      enabled: placementSchedule.recurrence.enabled && updateSeries,
      weekdaysBitField: getBitFieldFromWeekDays(
        placementSchedule.recurrence.weekdays
      )
    },
    facilityId: placementSchedule.facility?.id,
    trainingSiteIds: placementSchedule.trainingSiteIds,
    trainingSiteId:
      placementSchedule.trainingSiteId ?? placementSchedule.trainingArea?.id,
    themeScheduleId: placementSchedule.themeScheduleId,
    capacity: placementSchedule.capacity,
    supervisorIds: placementSchedule.supervisors.map(
      (supervisor: Supervisor) => supervisor.id
    ),
    studentGroupId: placementSchedule.studentGroupId,
    studentGroupName: placementSchedule.studentGroupName,
    studentGroupIds:
      placementSchedule.studentGroupIds &&
      placementSchedule.studentGroupIds.length > 0
        ? placementSchedule.studentGroupIds
        : [],
    studentIds: placementSchedule.studentIds?.map(
      (studentId: number) => studentId
    ),
    conflictOverriddenStudentsIds:
      placementSchedule.conflictOverriddenStudentsIds,
    transportStudentsIds:
      placementSchedule.transportStudentsIds.length > 0
        ? placementSchedule.transportStudentsIds
        : [],
    accommodationStudentsIds:
      placementSchedule.accommodationStudentsIds.length > 0
        ? placementSchedule.accommodationStudentsIds
        : [],
    bookableStudentIds:
      placementSchedule.bookableStudentsIds.length > 0
        ? placementSchedule.bookableStudentsIds
        : [],
    excludedGroupStudentsIds:
      placementSchedule.excludedGroupStudentsIds.length > 0
        ? placementSchedule.excludedGroupStudentsIds
        : [],
    notes: placementSchedule.notes,
    equipmentIds: placementSchedule.equipmentIds
  };
}

const DaysOfWeekChoices = [
  { id: 1, name: Weekday.MONDAY },
  { id: 2, name: Weekday.TUESDAY },
  { id: 4, name: Weekday.WEDNESDAY },
  { id: 8, name: Weekday.THURSDAY },
  { id: 16, name: Weekday.FRIDAY },
  { id: 32, name: Weekday.SATURDAY },
  { id: 64, name: Weekday.SUNDAY }
] as { id: number; name: Weekday }[];

function getDaysFromWeekdaysBitField(bitField: number): Weekday[] {
  return DaysOfWeekChoices.slice()
    .reverse()
    .reduce((daysArrAcc, day) => {
      if (bitField / day.id >= 1) {
        bitField = bitField % day.id;
        return daysArrAcc.concat([day.name]);
      } else {
        return daysArrAcc;
      }
    }, [] as Weekday[]);
}

function getBitFieldFromWeekDays(weekdays: Weekday[]): number {
  let sum = 0;
  weekdays.forEach((weekday: Weekday) => {
    sum += DaysOfWeekChoices.find((y) => y.name === weekday)?.id || 0;
  });
  return sum;
}

function getAcademicBookingStatusFromDTO(
  bookingStatus?: string
): AcademicBookingStatus | undefined {
  if (bookingStatus) {
    switch (bookingStatus) {
      case AcademicBookingDTO.AWAITING_APPROVAL:
        return AcademicBookingStatus.AWAITING_APPROVAL;
      case AcademicBookingDTO.APPROVED:
        return AcademicBookingStatus.APPROVED;
      case AcademicBookingDTO.REJECTED:
        return AcademicBookingStatus.REJECTED;
      case AcademicBookingDTO.CANCELLED:
        return AcademicBookingStatus.CANCELLED;
      default:
        throw new Error(`unknown booking status: ${bookingStatus}`);
    }
  }
}

function getRecurrenceModel(dto: RecurrenceDTO): Recurrence {
  return {
    id: dto.id,
    frequencyType: dto.frequencyType,
    activeDate: getDateFromDtoDateString(dto.activeDate),
    expiryDate: getDateFromDtoDateString(dto.expiryDate),
    arrivalDate: dto.arrivalDate
      ? getDateFromDtoDateString(dto.arrivalDate)
      : undefined,
    departureDate: dto.departureDate
      ? getDateFromDtoDateString(dto.departureDate)
      : undefined,
    enabled: dto.enabled,
    weekdays: getDaysFromWeekdaysBitField(dto.weekdaysBitField),
    future: dto.future,
    series: dto.series,
    interval: dto.interval,
    maxOccurrences: dto.maxOccurrences
  } as Recurrence;
}

function getPlacementScheduleCreateResponseDTO(
  placementScheduleCreateResponseDTO: PlacementScheduleCreateResponseDTO
): PlacementScheduleCreateResponse {
  return {
    ...placementScheduleCreateResponseDTO
  };
}

function getDtoFromAcademicBookingStatus(
  bookingStatus?: AcademicBookingStatus
): string | undefined {
  if (bookingStatus !== undefined) {
    switch (bookingStatus) {
      case AcademicBookingStatus.AWAITING_APPROVAL:
        return AcademicBookingDTO.AWAITING_APPROVAL;
      case AcademicBookingStatus.APPROVED:
        return AcademicBookingDTO.APPROVED;
      case AcademicBookingStatus.REJECTED:
        return AcademicBookingDTO.REJECTED;
      case AcademicBookingStatus.CANCELLED:
        return AcademicBookingDTO.CANCELLED;
    }
  }
}

function getPlacementScheduleGroupParamsDTO(
  placementScheduleGroupParams?: PlacementScheduleGroupParams
): PlacementScheduleGroupParamsDto {
  if (!placementScheduleGroupParams) {
    return {};
  }

  const placementScheduleGroupParamsDto: PlacementScheduleGroupParamsDto = {};

  const {
    moduleScheduleId,
    themeScheduleId,
    facilityId,
    trainingAreaId,
    activityId,
    activityTypeId,
    studentId,
    startDateTime,
    endDateTime,
    studentGroupId,
    approverId,
    bookingStatus,
    groupBy,
    search,
    bookedByUser
  } = placementScheduleGroupParams;

  if (groupBy !== undefined) {
    placementScheduleGroupParamsDto.groupedBy =
      getDtoFromPlacementScheduleGroupBy(groupBy);
  }

  if (moduleScheduleId !== undefined) {
    placementScheduleGroupParamsDto.moduleScheduleId = moduleScheduleId;
  }

  if (themeScheduleId !== undefined) {
    placementScheduleGroupParamsDto.themeScheduleId = themeScheduleId;
  }

  if (facilityId !== undefined) {
    placementScheduleGroupParamsDto.facilityId = facilityId;
  }

  if (trainingAreaId !== undefined) {
    placementScheduleGroupParamsDto.trainingAreaId = trainingAreaId;
  }

  if (activityId !== undefined) {
    placementScheduleGroupParamsDto.activityId = activityId;
  }

  if (activityTypeId !== undefined) {
    placementScheduleGroupParamsDto.activityTypeId = activityTypeId;
  }

  if (studentId !== undefined) {
    placementScheduleGroupParamsDto.studentId = studentId;
  }

  if (startDateTime) {
    placementScheduleGroupParamsDto.startDateTime =
      getDtoDateStringFromDate(startDateTime);
  }

  if (endDateTime) {
    placementScheduleGroupParamsDto.endDateTime =
      getDtoDateStringFromDate(endDateTime);
  }

  if (studentGroupId !== undefined) {
    placementScheduleGroupParamsDto.studentGroupId = studentGroupId;
  }

  if (approverId !== undefined) {
    placementScheduleGroupParamsDto.approverId = approverId;
  }

  if (bookingStatus !== undefined) {
    placementScheduleGroupParamsDto.bookingStatus =
      getDtoFromAcademicBookingStatus(bookingStatus);
  }

  if (search !== undefined) {
    placementScheduleGroupParamsDto.search = search;
  }

  if (bookedByUser !== undefined) {
    placementScheduleGroupParamsDto.bookedByUser = bookedByUser;
  }

  return placementScheduleGroupParamsDto;
}

function getPlacementScheduleParamsDTO(
  placementScheduleParams?: PlacementScheduleParams
): PlacementScheduleParamsDto {
  if (!placementScheduleParams) {
    return {};
  }

  const placementScheduleParamsDto: PlacementScheduleParamsDto = {};

  const {
    moduleScheduleId,
    themeScheduleId,
    facilityId,
    trainingAreaId,
    activityId,
    activityTypeId,
    studentId,
    startDateTime,
    endDateTime,
    approverId,
    studentGroupId,
    bookingStatus,
    search,
    bookedByUser
  } = placementScheduleParams;

  if (moduleScheduleId !== undefined) {
    placementScheduleParamsDto.moduleScheduleId = moduleScheduleId;
  }

  if (themeScheduleId !== undefined) {
    placementScheduleParamsDto.themeScheduleId = themeScheduleId;
  }

  if (facilityId !== undefined) {
    placementScheduleParamsDto.facilityId = facilityId;
  }

  if (trainingAreaId !== undefined) {
    placementScheduleParamsDto.trainingAreaId = trainingAreaId;
  }

  if (activityId !== undefined) {
    placementScheduleParamsDto.activityId = activityId;
  }

  if (activityTypeId !== undefined) {
    placementScheduleParamsDto.activityTypeId = activityTypeId;
  }

  if (studentId !== undefined) {
    placementScheduleParamsDto.studentId = studentId;
  }

  if (startDateTime) {
    placementScheduleParamsDto.startDateTime =
      getDtoDateStringFromDate(startDateTime);
  }

  if (endDateTime) {
    placementScheduleParamsDto.endDateTime =
      getDtoDateStringFromDate(endDateTime);
  }

  if (approverId) {
    placementScheduleParamsDto.approverId = approverId;
  }

  if (studentGroupId !== undefined) {
    placementScheduleParamsDto.studentGroupId = studentGroupId;
  }

  if (bookingStatus !== undefined) {
    placementScheduleParamsDto.bookingStatus =
      getDtoFromAcademicBookingStatus(bookingStatus);
  }

  if (search !== undefined) {
    placementScheduleParamsDto.search = search;
  }

  if (bookedByUser !== undefined) {
    placementScheduleParamsDto.bookedByUser = bookedByUser;
  }

  return placementScheduleParamsDto;
}
