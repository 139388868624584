export type RoomBookingDTO = {
  id: number;
  startDateTime: string;
  endDateTime: string;
  trainingSiteId: number;
  trainingSiteName: string;
  facilityId: number;
  facilityName: string;
  capacity: number;
  title: string;
  notes?: string;
  bookedByUserId: number;
  lastUpdatedByUserId?: number;
  approvedByUserId: number;
  status: string;
  comment?: string;
  approvalDateTime?: string;
  equipmentIds?: number[];
  studentIds?: number[];
  staffIds: number[];
  canApprove: boolean;
  placementScheduleId: number;
  placementScheduleStatus?: string;
  moduleScheduleId?: number; // Bookings created with pre-rebuild system don't have this
  studentGroupId?: number;
  themeScheduleId: number;
  activityId?: number;
  activityTypeId?: number;
  driverStaffId?: number;
  trainingSitesIds?: number[];
  bookingType: string;
  recurrence?: RoomBookingRecurrenceDTO;
  trainingSiteBookable: boolean;
  trainingIsVenueRequest?: boolean;
  deletionReason?: string;
  bookingKmTravelled?: number;
  activityName?: string;
  moduleName?: string;
  themeName?: string;
  bookedByUser?: {
    displayName: string;
  };
  lastUpdatedUser?: {
    displayName: string;
  };
  conflictsCount?:number;
};

export type RoomBookingRecurrenceDTO = {
  id: number;
  arrivalDate: string;
  departureDate: string;
  dayOfWeekSum: number;
  dayOfWeekBitFieldAsEnumSet?: string[];
};

export const RoomBookingStatusDTO = {
  APPROVED: 'Approved',
  AWAITING_APPROVAL: 'Awaiting Approval',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled'
};

export const RoomBookingTypeDTO = {
  ACCOMMODATION: 'Accommodation',
  ACADEMIC: 'Academic',
  TRANSPORT: 'Transport',
  ADHOC: 'Adhoc'
};

export const PlacementScheduleStatusDTO = {
  CANCELLED: 'Cancelled',
  ACTIVE: 'Active'
};
