import { useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { DateRange, DateRangeType, ThemableBoxType } from 'src/types';
import { DatePickerModal } from './DatePickerModal';

export type DateRangeControlProps = {
  dateRange: DateRange;
  dateRangeType: DateRangeType;
  onNextDateRange: () => void;
  onPreviousDateRange: () => void;
  onSetDateRange?: (dateInRange: Date) => void;
};

export const DateRangeControl = ({
  dateRange,
  dateRangeType,
  onNextDateRange,
  onPreviousDateRange,
  onSetDateRange
}: DateRangeControlProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const dateText = useMemo(() => {
    const fromDateMoment = moment(dateRange.fromDate);

    switch (dateRangeType) {
      case DateRangeType.YEAR: {
        return fromDateMoment.format('YYYY');
      }

      case DateRangeType.MONTH: {
        return fromDateMoment.format('MMMM YYYY');
      }

      case DateRangeType.WEEK: {
        const toDateMoment = moment(dateRange.toDate);
        const isSameMonth =
          fromDateMoment.get('month') === toDateMoment.get('month');
        const isSameYear =
          fromDateMoment.get('year') === toDateMoment.get('year');
        const fromDateFormat = `DD${isSameMonth ? '' : ' MMM'}${
          isSameYear ? '' : ' YYYY'
        }`;
        const toDateFormat = 'DD MMM YYYY';

        return `${fromDateMoment.format(
          fromDateFormat
        )} - ${toDateMoment.format(toDateFormat)}`;
      }

      default:
        throw new Error('unknown date range type');
    }
  }, [dateRange, dateRangeType]);

  return (
    <ThemableBox type={ThemableBoxType.DATE_RANGE_CONTROL}>
      {onSetDateRange && (
        <DatePickerModal
          show={showModal}
          dateRange={dateRange}
          onClose={() => setShowModal(false)}
          dateRangeType={dateRangeType}
          onSetDateRange={onSetDateRange}
        />
      )}
      <Stack direction="row">
        <Box>
          <Typography noWrap onClick={() => setShowModal(true)}>
            {dateText}
          </Typography>
        </Box>

        <IconButton size="small" onClick={onPreviousDateRange}>
          <KeyboardArrowLeftIcon fontSize="inherit" />
        </IconButton>

        <IconButton size="small" onClick={onNextDateRange}>
          <KeyboardArrowRightIcon fontSize="inherit" />
        </IconButton>
      </Stack>
    </ThemableBox>
  );
};
