import moment, { unitOfTime } from 'moment';
import { CalendarYear, DateRange, DateRangeType } from 'src/types';
import { Weekday } from 'src/types';

export function getDateFromDtoDateString(dateString: string): Date {
  return new Date(dateString);
}

export function getDateFromString(date: string, format: string): Date {
  return moment(
    date,
   format
  ).toDate();
}

export function getDisplayDateStringFromDate(
  date: Date,
  format: string = DATE_TIME_FORMAT.DEFAULT
): string {
  return moment(date).format(format);
}

/**
 *
 * @param year
 * @param month
 * @param day
 * @returns the formatted date string based on the year month and day.
 */
export function getCalendarDisplayDateString(
  year: number,
  month: number,
  day: number
) {
  return getDisplayDateStringFromDate(
    new Date(year, month, day),
    DATE_TIME_FORMAT.FORMIK_DATE_FIELD_FORMAT
  );
}

/**
 *
 * @param date
 * @param isReturnUTCDate - If set to true, return current date value without timezone,
 * else returns date with current timezone information.
 * Optional - defaults to false
 * @returns
 */
export function getDtoDateStringFromDate(
  date: Date,
  isReturnUTCDate?: boolean
): string {
  if (isReturnUTCDate) {
    return moment(date).format(DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT);
  } else {
    return date.toISOString();
  }
}

export function isValidDate(date: Date): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export const DATE_TIME_FORMAT = {
  DEFAULT: 'DD-MM-YYYY,    HH:mm:ss',
  TIME_FORMAT: 'HH:mm',
  DATE_FORMAT: 'ddd D MMMM YYYY',
  FORMAL_DATE_FORMAT: 'DD/M/YYYY',
  FORMAL_DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm',
  DATE_TIME_FORMAT: 'ddd D MMMM YYYY - HH:mm',
  EXCEL_DATE_FORMAT: 'DD MMM YYYY',
  EXCEL_TIME_FORMAT: 'HH:mm',
  FORMIK_DATE_FIELD_FORMAT: 'YYYY-MM-DD',
  ABSOLUTE_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSS\\Z',
  END_DATE_TIME_FORMAT: 'DD-MM-YYYY, HH:mm',
  DAY_MONTH_YEAR_TIME: 'DD MMMM YYYY HH:mm',
  DATE_FORMAT_YYYYMMDD_SLASH: 'YYYY/MM/DD',
  FILE_TIMESTAMP_FORMAT: 'YYYY-MM-DD_HH-mm-ss',
  YEAR_FORMAT: 'YYYY',
  MONTH_FORMAT: 'MM YYYY',
  DATE_AND_TIME: 'YYYY-MM-DD,    HH:mm',
};

export const LAST_HOUR_OF_DAY = 23;
export const LAST_MINUTE_OF_HOUR = 59;

/**
 * This function is intended to be used for endDates before making a request to the backend.
 * The reason behind it is that currently the date component will always
 * set the date to 00:00:00.000Z, which is not ideal to fetch events or bookings
 * that are happening on the same day. this function will set the time to 23:59
 * @param date the date to be converted to end date time
 * @returns date converted to end date time
 */
export const getDateWithLastHourMinute = (date: Date): Date => {
  date.setHours(LAST_HOUR_OF_DAY);
  date.setMinutes(LAST_MINUTE_OF_HOUR);
  return date;
};

export type WeekdayWithID = { id: number; name: Weekday };

export const DaysOfWeekChoices = [
  { id: 1, name: Weekday.MONDAY },
  { id: 2, name: Weekday.TUESDAY },
  { id: 4, name: Weekday.WEDNESDAY },
  { id: 8, name: Weekday.THURSDAY },
  { id: 16, name: Weekday.FRIDAY },
  { id: 32, name: Weekday.SATURDAY },
  { id: 64, name: Weekday.SUNDAY }
] as WeekdayWithID[];

export function getScheduledWeekdaysDisplayString(
  startTime: Date,
  endTime: Date,
  daysArray: Weekday[]
): string {
  return `From ${getDisplayDateStringFromDate(
    startTime,
    DATE_TIME_FORMAT.TIME_FORMAT
  )} to ${getDisplayDateStringFromDate(
    endTime,
    DATE_TIME_FORMAT.TIME_FORMAT
  )}  ${
    daysArray.length > 0
      ? // eslint-disable-next-line no-useless-concat
        '\n' + `Every ${daysArray.map(getStringFromWeekday).join(', ')}`
      : ''
  }`;
}

export function getStringFromWeekday(weekday: Weekday) {
  switch (weekday) {
    case Weekday.MONDAY:
      return 'Monday';
    case Weekday.TUESDAY:
      return 'Tuesday';
    case Weekday.WEDNESDAY:
      return 'Wednesday';
    case Weekday.THURSDAY:
      return 'Thursday';
    case Weekday.FRIDAY:
      return 'Friday';
    case Weekday.SATURDAY:
      return 'Saturday';
    case Weekday.SUNDAY:
      return 'Sunday';
  }
}

export const WEEKDAYS = [
  Weekday.MONDAY,
  Weekday.TUESDAY,
  Weekday.WEDNESDAY,
  Weekday.THURSDAY,
  Weekday.FRIDAY,
  Weekday.SATURDAY,
  Weekday.SUNDAY
];

const getMomentUnitOfTimeFromDateRangeType = (
  dateRangeType: DateRangeType
): unitOfTime.Base => {
  switch (dateRangeType) {
    case DateRangeType.YEAR:
      return 'year';
    case DateRangeType.MONTH:
      return 'month';
    case DateRangeType.WEEK:
      return 'week';
    case DateRangeType.DAY:
      return 'day';
    default:
      throw new Error('unknown date range type');
  }
};

export const getDateRangeFromDateAndDateRangeType = (
  date: Date,
  dateRangeType: DateRangeType
): DateRange => {
  const dateMoment = moment(date);
  const unitOfTime = getMomentUnitOfTimeFromDateRangeType(dateRangeType);
  return {
    fromDate: dateMoment.startOf(unitOfTime).toDate(),
    toDate: dateMoment.endOf(unitOfTime).toDate()
  };
};

export const addTimeToDate = (
  date: Date,
  quantity: number,
  quantityType: DateRangeType
): Date => {
  const momentDate = moment(date);

  const momentUnitOfTime = getMomentUnitOfTimeFromDateRangeType(quantityType);
  const quantityString = Math.abs(quantity).toString();
  return (
    quantity > 0
      ? momentDate.add(quantityString, momentUnitOfTime)
      : momentDate.subtract(quantityString, momentUnitOfTime)
  ).toDate();
};

export const getTimeMeasureGroupId = (
  date: Date,
  timeMeasureGroupType: 'day'
): string => {
  switch (timeMeasureGroupType) {
    case 'day':
      return moment(date).format(DATE_TIME_FORMAT.FORMAL_DATE_FORMAT);
    default:
      throw new Error('unsupported time measure group type');
  }
};

export const getTimeMeasureGroupDateRangeFromId = (
  timeMeasureGroupId: string,
  timeMeasureGroupType: 'day'
): DateRange => {
  switch (timeMeasureGroupType) {
    case 'day': {
      const dayDate = moment(
        timeMeasureGroupId,
        DATE_TIME_FORMAT.FORMAL_DATE_FORMAT
      );

      return {
        fromDate: dayDate.startOf('day').toDate(),
        toDate: dayDate.endOf('day').toDate()
      };
    }

    default:
      throw new Error('unsupported time measure group type');
  }
};

export const getTimeMeasureGroupNameFromId = (
  timeMeasureGroupId: string,
  timeMeasureGroupType: 'day' | 'month' | 'year'
): string => {
  switch (timeMeasureGroupType) {
    case 'day': {
      const dayDate = moment(
        timeMeasureGroupId,
        DATE_TIME_FORMAT.FORMAL_DATE_FORMAT
      ).toDate();
      return getDisplayDateStringFromDate(
        dayDate,
        DATE_TIME_FORMAT.EXCEL_DATE_FORMAT
      );
    }

    default:
      throw new Error('unsupported time measure group type');
  }
};

export const isDateBeforeAnotherDate = (date: Date, anotherDate: Date): boolean => {
  return moment(date).isBefore(moment(anotherDate));
};

export const isDateWithinCalendarYear = (date: Date, year: CalendarYear): boolean => {
  return date.getFullYear() === parseInt(year.calendarYear);
};

export const getDateTimeWithDate = (dateTime: Date, newDate: Date) => {
  const dateTimeNew = new Date(dateTime);
  dateTimeNew.setFullYear(
    newDate.getFullYear()
  );
  dateTimeNew.setMonth(
    newDate.getMonth()
  );
  dateTimeNew.setDate(
    newDate.getDate()
  );
  return dateTimeNew;
};

export const getDateTimeWithTime = (dateTime: Date, newTime: Date) => {
  const dateTimeNew = new Date(dateTime);
  dateTimeNew.setHours(
    newTime.getHours()
  );
  dateTimeNew.setMinutes(
    newTime.getMinutes()
  );
  return dateTimeNew;
};

export const getDayDifference = (startDate: Date, endDate: Date) => {
  return moment(endDate).diff(moment(startDate),'day');
};

export const getDateTimeWithHoursMinutes = (dateTime: Date, hours: number, minutes: number) => {
  return getDateTimeWithTime(dateTime, new Date(1970, 0, 1, hours, minutes, 0));
};

export const DEFAULT_MAX_DOWNLOAD_DAYS = 30;
