import { Stack, Typography, Box } from '@mui/material';
import { Pill, PillStyle } from 'src/common';
import { BookingsAccordion } from './BookingsAccordion';

export type BookingsGroup = {
  title: string;
  count: number;
  render: () => JSX.Element;
};

export type BookingsGroupLayout = {
  groups: BookingsGroup[];
  key?: string | number;
};

export function BookingsGrouped({ groups, key }: BookingsGroupLayout) {
  return (
    <Box key={key}>
      {groups.map((group) => {
        return (
          <BookingsAccordion
            defaultExpanded={groups.length === 1}
            renderSummary={() => {
              return (
                <Stack direction="row" spacing={2}>
                  <Typography display="inline">
                    List of Bookings - {group.title}
                  </Typography>
                  <Pill
                    pillStyle={PillStyle.GREY}
                    label={`${group.count.toString()} Booking${
                      group.count > 1 ? 's' : ''
                    }`}
                  />
                </Stack>
              );
            }}
            renderDetails={group.render}
          />
        );
      })}
    </Box>
  );
}
