import { RoomBookingDTO } from 'src/types/dto';
import { Weekday } from 'src/types/enums';

export type RoomBooking = {
  status: RoomBookingStatus;
  approvalDateTime?: Date;
  startDateTime: Date;
  endDateTime: Date;
  bookingType: RoomBookingType;
  recurrence?: RoomBookingRecurrence;
  facilityType?: string;
  placementScheduleStatus?: PlacementScheduleStatus;
} & Omit<
  RoomBookingDTO,
  | 'status'
  | 'approvalDateTime'
  | 'startDateTime'
  | 'endDateTime'
  | 'bookingType'
  | 'recurrence'
  | 'placementScheduleStatus'
>;

export enum RoomBookingStatus {
  APPROVED,
  AWAITING_APPROVAL,
  REJECTED,
  CANCELLED
}

export enum PlacementScheduleStatus {
  ACTIVE,
  CANCELLED
}

export enum RoomBookingType {
  ACADEMIC,
  ACCOMMODATION,
  TRANSPORT,
  ADHOC
}

export type RoomBookingRecurrence = {
  id?: number;
  enabled: boolean;
  arrivalDate: Date;
  departureDate: Date;
  daysOfWeek: Weekday[];
  dayOfWeekBitFieldAsEnumSet?: string[];
};
